import React from "react"
import { Link } from "gatsby"

export const Posts = ({ posts }) => {
  if (posts.length === 0) return <p>No blog posts</p>
  else {
    return posts.map(post => {
      const title = post.frontmatter.title || post.fields.slug

      return (
        <div
          key={post.fields.slug}
          className=" p-2 mb-3 flex flex-row justify-center"
        >
          <div className="border-transparent border-2 border-black-100 bg-gray-50 hover:bg-gray-300 flex flex-column xl:w-1/3 md:w-2/3 sm:w-3/4 w-11/12 xl:p-3 md:p-2 p-1">
            <Link
              to={post.fields.slug}
              itemProp="url"
              className="no-underline text-green-900"
            >
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <div className="font-bold">
                  <span itemProp="headline">
                    <u>{title}</u>
                  </span>
                </div>
                <div className="text-xs font-light text-gray-400">
                  {post.frontmatter.date}
                </div>
                <div className="font-normal text-base text-gray-700">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </div>
              </article>
            </Link>
          </div>
        </div>
      )
    })
  }
}
