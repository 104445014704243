import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SearchBar } from "../components/search"

import { useFlexSearch } from "react-use-flexsearch"
import { Posts } from "../components/posts"

const BlogIndex = ({
  data: {
    localSearchPages: { index, store },
    allMarkdownRemark: { nodes },
    site: { siteMetadata },
  },
  location,
}) => {
  const siteTitle = siteMetadata?.title || `Title`
  const description = siteMetadata?.description || ``
  const [searchQuery, setSearchQuery] = React.useState("")

  const unFlattenResults = results =>
    results.map(post => {
      const { date, slug, title, excerpt, description } = post
      return {
        excerpt,
        fields: { slug },
        frontmatter: { title, date, description },
      }
    })

  const results = useFlexSearch(searchQuery, index, store)
  const posts = searchQuery ? unFlattenResults(results) : nodes

  return (
    <Layout location={location} title={siteTitle} description={description}>
      <SEO title="All posts" />
      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <Posts posts={posts} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    localSearchPages {
      index
      store
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
