import React from "react"

export const SearchBar = ({ searchQuery, setSearchQuery }) => (
  <div className="flex flex-row justify-center">
    <input
      value={searchQuery}
      onChange={e => setSearchQuery(e.target.value)}
      type="text"
      id="header-search"
      placeholder="Search blog posts"
      name="s"
      className="mb-4 font-medium h-6 p-1 xl:w-1/3 md:w-2/3 sm:w-3/4 w-11/12"
    />
  </div>
)
